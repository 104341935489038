import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpHeaders, HttpOptions, HttpParams, HttpResponse } from '@capacitor/core';
import { DeviceRegisterTokenApiResponse } from '../models/auth.model';
import { Device, DeviceRegisterData, PaginatedData, SuccessResponse, User } from '../models/cp2-api.model';
import { environment } from '../../environments/environment';
import {
    ExtendedFolder,
    ExtendedForm,
    Folder,
    FolderToForm,
    Form,
} from '../pages/admin/form-editor/form-editor.component';
import {
    PatientListGroup,
    VC_Case,
    VcPatientListItem,
    ViewContent,
} from '../models/view-content.models/view-content.model';
import {
    Center,
    Corporation,
    Department,
    Location,
    ServiceUnit,
} from '../models/view-content.models/view-content-organization-domain.model';
import { Area, SubArea } from '../models/patient-records.model';
import { Directory, Item, Workflow } from '../pages/admin/form-manager/models/form-manager.model';

@Injectable({
    providedIn: 'root',
})
export class Cp2ApiService {
    private static readonly TAG = 'Cp2ApiService';
    public activeAnonymous = false;
    private debugMode = false;

    private report: any[] = [];

    public constructor() {}

    //#region Device
    public async requestDeviceRegisterToken(
        accessToken: string,
        userId: number
    ): Promise<DeviceRegisterTokenApiResponse> {
        const response = await CapacitorHttp.post({
            url: environment.apiServer + '/device/token',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`, // Added accessToken in the Authorization header
            },
            data: { userId },
        });

        return response.data;
    }

    //#endregion

    //#region Corporations, locations, departments, service units, area, subareas
    public async getAllCenters(accessToken: string, page = 1, pageSize = 10): Promise<Center[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/organization/centers',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    public async getAllCorporations(accessToken: string, page = 1, pageSize = 10): Promise<Corporation[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/organization/corporations',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    public async getAllLocations(accessToken: string, page = 1, pageSize = 10): Promise<Location[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/organization/locations',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    public async getAllDepartments(accessToken: string, page = 1, pageSize = 1000): Promise<Department[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/organization/departments',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    public async getAllServiceUnits(accessToken: string, page = 1, pageSize = 10): Promise<ServiceUnit[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/organization/serviceunits',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    public async getAllAreas(accessToken: string, page = 1, pageSize = 10): Promise<Area[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/area',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    public async getAllSubAreas(accessToken: string, page = 1, pageSize = 10): Promise<SubArea[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/subarea',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    //#endregion

    //#region Device administration
    public async requestDeviceRegister(
        accessToken: string,
        registerToken: string,
        deviceId: string,
        displayName: string,
        user?: User,
        corporation_id?: string,
        location_id?: string,
        department_id?: string,
        service_unit_id?: string
    ): Promise<DeviceRegisterData> {
        const url = environment.apiServer + '/device/register';
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Authorization': `Bearer ${accessToken}`,
        };

        const data: any = {
            registerToken,
            deviceId,
            displayName,
        };

        if (user) {
            data.user = user;
        }
        if (corporation_id) {
            data.corporation_id = corporation_id;
        }
        if (location_id) {
            data.location_id = location_id;
        }
        if (department_id) {
            data.department_id = department_id;
        }
        if (service_unit_id) {
            data.service_unit_id = service_unit_id;
        }

        const response: SuccessResponse<DeviceRegisterData> = await CapacitorHttp.post({
            url,
            headers,
            data,
        });

        return response.data;
    }

    public async getAllDevicesPage(accessToken: string, page = 1, pageSize = 10): Promise<PaginatedData<Device>> {
        const response: SuccessResponse<PaginatedData<Device>> = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/devices',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            params: {
                page: page.toString(),
                page_size: pageSize.toString(),
            },
        });

        return response.data;
    }

    public async getDeviceId(deviceId: number, accessToken: string): Promise<Device> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/devices/' + deviceId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });

        return response.data;
    }

    public async getDevicesForUserIdPage(
        userId: number,
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<PaginatedData<Device>> {
        const response: SuccessResponse<PaginatedData<Device>> = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/users/devices/' + userId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            params: {
                page: page.toString(),
                page_size: pageSize.toString(),
            },
        });

        return response.data;
    }

    public async removeDeviceFromUser(
        deviceId: number,
        userId: number,
        accessToken: string
    ): Promise<{ message: string }> {
        const response: SuccessResponse<{ message: string }> = await CapacitorHttp.delete({
            url: environment.apiServer + `/admin/devices/${deviceId}/user/${userId}/`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });

        return response.data;
    }

    public async deleteDevice(id: number, accessToken: string): Promise<any> {
        const url = environment.apiServer + `/admin/devices/${id}`;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Authorization': `Bearer ${accessToken}`,
        };

        const response = await CapacitorHttp.delete({ url, headers });

        return response.data;
    }

    public async patchDevice(device: Device, accessToken: string): Promise<any> {
        const response = await CapacitorHttp.patch({
            url: environment.apiServer + `/admin/devices/${device.id}/`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: { ...device },
        });

        return response.data;
    }

    //#endregion

    //#region Users
    public async getAllUsersPage(accessToken: string, page = 1, pageSize = 10): Promise<PaginatedData<User>> {
        const httpOptions: HttpOptions = {
            url: environment.apiServer + '/admin/users',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            params: {
                page: page.toString(),
                page_size: pageSize.toString(),
            },
        };

        const response: SuccessResponse<PaginatedData<User>> = await CapacitorHttp.get(httpOptions);

        return response.data ?? [];
    }

    public async getUserInfo(userId: number, accessToken: string): Promise<User> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/users/' + userId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });

        return response.data;
    }

    public async patchUser(user: User, accessToken: string): Promise<User> {
        const url = environment.apiServer + '/admin/users/' + user.id;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Authorization': `Bearer ${accessToken}`,
        };
        const data = { ...user };

        const response: SuccessResponse<User> = await CapacitorHttp.patch({
            url,
            headers,
            data,
        });

        return response.data;
    }

    public async postUser(user: User, accessToken: string) {
        const response = await CapacitorHttp.post({
            url: environment.apiServer + '/admin/users',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: { ...user },
        });

        return response.data;
    }

    //#endregion

    //#region Form-Manager (for form editor)
    public async getAllItemTypes() {
        try {
            const response = await CapacitorHttp.get({
                url: '/assets/item-type-config.json', // Relative path to the JSON file
            });

            return response.data;
        } catch (error) {
            console.error('Error fetching JSON file:', error);
            throw error;
        }
    }

    public async getAllEntities<T extends Directory | Workflow>(accessToken: string): Promise<T[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/formeditor/workflows',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    public async getAllItems(accessToken: string): Promise<Item[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/formeditor/actionitems',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    public async postDirectory(directory: Directory, accessToken: string): Promise<Directory> {
        const response: any = await CapacitorHttp.post({
            url: environment.apiServer + '/formeditor/workflows/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: directory,
        });
        return response.data.results;
    }

    public async putDirectory(directory: Directory, directoryId: string, accessToken: string): Promise<Directory> {
        const response: any = await CapacitorHttp.put({
            url: environment.apiServer + `/formeditor/workflows/${directoryId}/`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: directory,
        });
        return response.data;
    }

    public async postItem(item: Item, accessToken: string): Promise<Item> {
        const response: any = await CapacitorHttp.post({
            url: environment.apiServer + '/formeditor/actionitems/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: item,
        });
        return response.data.results;
    }

    public async putItem(item: Item, accessToken: string): Promise<Item> {
        const response: any = await CapacitorHttp.put({
            url: environment.apiServer + `/formeditor/actionitems/${item.id}/`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: item,
        });

        return response.data;
    }

    //#endregion

    //#region Forms (for form editor)
    //@deprecated
    public async getFolder(accessToken: string): Promise<Folder[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/folder/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });

        return response.data.results;
    }

    //@deprecated
    public async getForms(accessToken: string): Promise<Form[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/form/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
        return response.data.results;
    }

    //@deprecated
    public async getFolderToFormsById(folder_id: string, accessToken: string): Promise<FolderToForm[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/folder_to_form/?folder_id=' + folder_id,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });

        return response.data.results;
    }

    //@deprecated
    public async getFolderToFormFormsById(form_id: string, accessToken: string): Promise<FolderToForm> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/folder_to_form/detail/' + form_id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });

        console.log(response);
        return response.data;
    }

    //@deprecated
    public async updateFolderToForm(req: FolderToForm, accessToken: string): Promise<any> {
        if (accessToken) {
            return await CapacitorHttp.put({
                url: environment.apiServer + '/admin/folder_to_form/' + req.id + '/',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'X-Authorization': `Bearer ${accessToken}`,
                },
                data: req,
            });
        }
    }

    //@deprecated
    public async saveNewRelatedFormToFolder(req: FolderToForm, accessToken: string): Promise<any> {
        if (accessToken) {
            return await CapacitorHttp.post({
                url: environment.apiServer + '/admin/folder_to_form/',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'X-Authorization': `Bearer ${accessToken}`,
                },
                data: req,
            });
        }
    }

    //@deprecated
    public async saveNewFolder(folder: Folder, accessToken: string): Promise<any> {
        delete folder.id;

        return await CapacitorHttp.post({
            url: environment.apiServer + '/admin/folder/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: folder,
        });
    }

    //@deprecated
    public async saveUpdatedFolder(folder: Folder, accessToken: string): Promise<any> {
        return await CapacitorHttp.put({
            url: environment.apiServer + '/admin/folder/' + folder.id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: folder,
        });
    }

    //@deprecated
    public async saveNewForm(form: Form, accessToken: string): Promise<any> {
        delete form.id;

        return await CapacitorHttp.post({
            url: environment.apiServer + '/admin/form/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: form,
        });
    }

    //@deprecated
    public async saveUpdatedForm(form: Form, accessToken: string): Promise<any> {
        return await CapacitorHttp.put({
            url: environment.apiServer + '/admin/form/' + form.id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
            data: form,
        });
    }

    // TODO: IMPLEMENT ADD FOLDER AND FORM METHOD!!! ASAP!

    //@deprecated
    public async deleteForm(form: ExtendedForm, accessToken: string): Promise<any> {
        return await CapacitorHttp.delete({
            url: environment.apiServer + '/admin/form/' + form.id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
    }

    //@deprecated
    public async deleteFolder(folder: ExtendedFolder, accessToken: string): Promise<any> {
        return await CapacitorHttp.delete({
            url: environment.apiServer + '/admin/folder/' + folder.id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
    }

    //@deprecated
    public async deleteFolderToForm(folderToFormId: number, accessToken: string): Promise<any> {
        return await CapacitorHttp.delete({
            url: environment.apiServer + '/admin/folder_to_form/' + folderToFormId + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });
    }

    //#endregion

    //#region ViewContent
    public async getCaseListForUser(userId: number, accessToken: string): Promise<PatientListGroup[]> {
        const url = environment.apiServer + '/viewcontent/locator/case.list.' + userId;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Authorization': `Bearer ${accessToken}`,
        };
        const request = { url, headers };
        const response = await CapacitorHttp.get(request);

        if (this.debugMode) console.log('getCaseListForUser', { request, response });

        return response.data;
    }

    public async getAllLocatorsForCaseId(caseId: string, token: string): Promise<string[]> {
        const path = `/viewcontent/case_locators/${caseId}`;
        const url = environment.apiServer + path;
        const request = {
            url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Authorization': `Bearer ${token}`,
            },
        };
        const response = await CapacitorHttp.get(request);

        if (this.debugMode) {
            console.log('getAllLocatorsForCaseId', { request, response });
        }

        return response.data.locators;
    }

    public async getViewContentByLocator(
        locator: string,
        accessToken: string,
        deviceId?: string,
        params?: string
    ): Promise<HttpResponse> {
        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Authorization': `Bearer ${accessToken}`,
        };

        if (deviceId) {
            headers['Device-id'] = deviceId;
        }

        let url = environment.apiServer + '/viewcontent/locator/' + locator;

        if (params) {
            url += `?${params}`;
        }

        const request = { url, headers };
        const resp = await CapacitorHttp.get(request);

        if (this.debugMode) {
            console.log('getViewContentByLocator', { request, response: resp });
        }

        if (resp.status === 400) {
            resp.data = undefined;
            return resp;
        } else {
            return resp;
        }
    }

    public async getVcForLocator<T>(
        locator: string,
        token: string,
        deviceId?: string,
        params?: HttpParams
    ): Promise<ViewContent<T> | null> {
        const headers: any = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Authorization': `Bearer ${token}`,
        };
        if (deviceId) headers['Device-id'] = deviceId;

        let url = environment.apiServer + '/viewcontent/locator/' + locator;

        const request = { url, headers, params };
        const response = await CapacitorHttp.get(request);

        if (this.debugMode) {
            console.log('getVcForLocator', { request, response });
        }

        if (response.status >= 200 && response.status < 300) {
            return response.data as ViewContent<T>;
        }

        console.error(`Error: ${response.status} for Locator: ${locator}`);
        return null;
    }

    public async getVcBatchForLocator<T>(
        locator: string,
        token: string,
        deviceId?: string,
        queryParams?: string
    ): Promise<ViewContent<T>[] | null> {
        const allowedLocatorStarts: { keyRoot: string; rootLength: number }[] = [
            { keyRoot: 'document.dicom', rootLength: 4 }, // Eg. 'document.dicom.offline.{caseId}.{uuid}
            { keyRoot: 'document.others', rootLength: 3 },
            { keyRoot: 'labor.results', rootLength: 3 },
            { keyRoot: 'case.overview', rootLength: 4 },
        ];
        const allowedLocatorStart = allowedLocatorStarts.find((e) => locator.startsWith(e.keyRoot));
        if (!allowedLocatorStart || locator.split('.').length > allowedLocatorStart.rootLength)
            throw Error(`Given locator is not allowed for batch fetch: ${locator}`);

        const header: any = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Authorization': `Bearer ${token}`,
        };
        if (deviceId) header['Device-id'] = deviceId;

        let url = environment.apiServer + '/viewcontent/batch/' + locator;

        if (queryParams) {
            url += `?${queryParams}`;
        }

        const request = { url, headers: header };
        const resp = await CapacitorHttp.get(request);

        if (this.debugMode) {
            console.log('getVcBatchForLocator', { request, response: resp });
        }

        if (resp.status >= 200 && resp.status < 300) {
            return resp.data as ViewContent<T>[];
        }
        console.error(`Error: ${resp.status} for Locator: ${locator}`);
        return null;
    }

    /**
     * @deprecated
     * @param locator
     * @param accessToken
     * @param deviceId
     * @param queryParams
     */
    public async getVcByIncommingLocator(
        locator: string,
        accessToken: string,
        deviceId?: string,
        queryParams?: string
    ): Promise<HttpResponse> {
        let header;

        if (deviceId) {
            header = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
                'Device-id': deviceId,
            };
        } else {
            header = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            };
        }

        // Basis-URL für den Request
        let url = environment.apiServer + '/viewcontent/locator/' + locator;

        // Wenn queryParams vorhanden sind, füge sie zur URL hinzu
        if (queryParams) {
            url += `?${queryParams}`;
        }

        const resp = await CapacitorHttp.get({
            url: url,
            headers: header,
        });

        if (resp.status === 400) {
            resp.data = undefined;
            return resp;
        } else {
            return resp;
        }
    }

    /**
     * @deprecated
     * @param locator
     * @param accessToken
     * @param deviceId
     * @param queryParams
     */
    public async getVcBatchByLocator(
        locator: string,
        accessToken: string,
        deviceId?: string,
        queryParams?: string
    ): Promise<HttpResponse> {
        let header;

        if (deviceId) {
            header = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
                'Device-id': deviceId,
            };
        } else {
            header = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            };
        }

        // Basis-URL für den Request
        let url = environment.apiServer + '/viewcontent/batch/' + locator;

        // Wenn queryParams vorhanden sind, füge sie zur URL hinzu
        if (queryParams) {
            url += `?${queryParams}`;
        }

        const resp = await CapacitorHttp.get({
            url: url,
            headers: header,
        });

        if (resp.status === 400) {
            resp.data = undefined;
            return resp;
        } else {
            return resp;
        }
    }

    public async putViewContent<T>(viewContent: ViewContent<T>, token: string): Promise<ViewContent<T>> {
        const locator = viewContent.locator;
        if (!locator) throw Error('Error on PUT ViewContent: no locator found.');

        const path = `/viewcontent/put/${locator}`;
        const request = {
            url: environment.apiServer + path,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Authorization': `Bearer ${token}`,
            },
            data: viewContent,
        };

        const response = await CapacitorHttp.put(request);

        if (response.status < 200 || response.status > 299) {
            throw Error(response.data.detail);
        }

        return response.data;
    }

    public async postViewContent<T>(viewContent: ViewContent<T>, token: string): Promise<any> {
        const locator = viewContent.locator;
        if (!locator) throw Error('Error on POST ViewContent: no locator found.');

        const path = `/viewcontent/post/`;
        const request: HttpOptions = {
            url: environment.apiServer + path,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Authorization': `Bearer ${token}`,
            },
            data: viewContent,
        };

        const response = await CapacitorHttp.post(request);

        return response.data;
    }

    public async createDocument(
        doc: { documenttext: string; case_id: string; document_type?: string; filename?: string },
        token: string
    ): Promise<any> {
        const path = `/docs-files/create_documents/`;
        const request: HttpOptions = {
            url: environment.apiServer + path,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Authorization': `Bearer ${token}`,
            },
            data: doc,
        };

        const response = await CapacitorHttp.post(request);

        return response.data;
    }

    public async getVcCaseDetails(uuid: string, accessToken: string): Promise<VC_Case> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + '/viewcontent/locator/case.details.' + uuid,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });

        return response.data;
    }

    public async getVcCaseListItem(uuid: string, accessToken: string): Promise<VcPatientListItem> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + '/viewcontent/locator/case.list.item.' + uuid,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
            },
        });

        return response.data;
    }

    // #endregion

    public async saveTask(task: any, accessToken: string): Promise<any> {
        const response = await CapacitorHttp.post({
            url: environment.apiServer + '/communication/task/post/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`, // Added accessToken in the Authorization header
            },
            data: task,
        });

        return response.data;
    }
}
